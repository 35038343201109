import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  static targets = ["traySlider", "contentSlider"];

  connect() {
    this.initMenu();
    this.expand = this.element.querySelectorAll(".js-menu-expand");
    this.collapse = this.element.querySelectorAll(".js-menu-collapse");
    this.wrapperTarget = this.element.querySelector(".js-menu-wrapper");
    this.collapse.forEach((el) => {
      el.addEventListener("click", () => {
        this.closeMenu(el.parentNode);
      });
    });
    this.expand.forEach((el) => {
      el.addEventListener("click", () => {
        this.expandMenu(el.parentNode);
      });
    });
    window.addEventListener('load', this.updateMenuContentHeight.bind(this))
  }

  closeMenu(el) {
    let menuCols = Array.from(el.querySelectorAll(".js-menu-col"));
    let collapse = el.querySelector(".js-menu-collapse");
    let expand = el.querySelector(".js-menu-expand");
    // remove first from menuCols array
    menuCols.shift();
    menuCols.forEach((col) => col.classList.add("-is-hidden"));
    expand.classList.remove("-is-hidden");
    collapse.classList.add("-is-hidden");
    let wrapper = document.querySelector("#menu");
    let header = document.querySelector(".js-header");
    wrapper.classList.remove("expanded");
    window.scrollTo(0, wrapper.offsetTop - header.offsetHeight);
  }

  expandMenu(el) {
    let menuCols = el.querySelectorAll(".js-menu-col");
    let collapse = el.querySelector(".js-menu-collapse");
    let expand = el.querySelector(".js-menu-expand");
    menuCols.forEach((col) => col.classList.remove("-is-hidden"));
    expand.classList.add("-is-hidden");
    collapse.classList.remove("-is-hidden");
    this.wrapperTarget.classList.add("expanded");
  }

  initMenu() {
    let savedMenuTrayActiveIndex = 0;

    // Create menuTray Swiper instance
    const menuTraySelector = ".js-menuTray";
    const menuTrayElement = this.element.querySelector(menuTraySelector);

    if (menuTrayElement) {
      this.menuTray = new Swiper(this.traySliderTarget, {
        slidesPerView: 3,
        slidesPerGroup: 1,
        initialSlide: 0,
        navigation: false,
        freeMode: false,
        watchOverflow: true,
        centeredSlides: true,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        centeredSlidesBounds: true,
        spaceBetween: 15,
        touchStartPreventDefault: false,
        allowTouchMove: false,
        breakpoints: {
          700: {
            threshold: 70,
            spaceBetween: 25,
          },
          1280: {
            threshold: 128,
            spaceBetween: 35,
          },
        },
      });
    }

    // Create menuContent Swiper instance
    const menuContentSelector = ".js-menuContent";
    const menuContentElement = this.element.querySelector(menuContentSelector);

    if (menuContentElement) {
      this.menuContent = new Swiper(this.contentSliderTarget, {
        slidesPerView: 1,
        initialSlide: 0,
        autoHeight: true,
        navigation: false,
        freeMode: false,
        centeredSlides: false,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        spaceBetween: 0,
        allowTouchMove: false,
        speed: 600,
        breakpoints: {
          700: {
            threshold: 70,
            centeredSlides: true
          },
        },
      });

      this.menuTray.activeIndex = savedMenuTrayActiveIndex;
      this.menuTray.updateSlidesClasses();
    }

    if (this.menuTray && this.menuContent) {
      this.element.querySelector(".js-menuTray").addEventListener("click", (event) => {
        const clickedButton = event.target.closest("button");
        const buttonIndex = Array.from(menuTrayElement.querySelectorAll("button")).indexOf(
          clickedButton
        );
        if (clickedButton) {
          this.menuContent.slideTo(buttonIndex);
          document
            .querySelectorAll(".js-menu-collapse")
            .forEach((el) => this.closeMenu(el.parentNode));
          this.menuTray.update();
          savedMenuTrayActiveIndex = buttonIndex;
          if (this.menuTray.activeIndex != savedMenuTrayActiveIndex) {
            this.menuTray.activeIndex = savedMenuTrayActiveIndex;
            this.menuTray.updateSlidesClasses();
          }
          this.menuTray.updateSlidesClasses();
        } else {
          this.menuTray.activeIndex = savedMenuTrayActiveIndex;
        }
      });
      window.addEventListener("resize", () => {
        if (this.menuTray.activeIndex != savedMenuTrayActiveIndex) {
          this.menuTray.activeIndex = savedMenuTrayActiveIndex;
          this.menuTray.updateSlidesClasses();
        }
      });
    }
  }

  slideTo(e) {
    let index = e.currentTarget.dataset.index
    this.menuContent.slideTo(index);
    this.menuTray.activeIndex = index;
    if (window?.innerWidth < 700) {
      this.menuTray.slideTo(index);
    }
    this.menuTray.updateSlidesClasses()
  }

  /**
   * Workaround for autoHeight not working on some edge cases
   * - initial slide has significantly less content than at least one other slide
   * - the height of the largest slide is at least 150% of the viewport height
   * - the page loads with the menu in view, and the above conditions are met
   */
  updateMenuContentHeight() {
    this.menuContent?.updateAutoHeight()
  }
}
